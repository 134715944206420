import React from "react";

import SEO from "../components/seo";

import relogio from "../images/relogio.svg";
import atendimento from "../images/atendimento.svg";
import organizacao from "../images/organizacao.svg";
import seguranca from "../images/seguranca.svg";

function QuemSomosPage() {
    return (
        <>
            <SEO title="Quem Somos" />
            <section className="quem-somos-banner"></section>
            <section className="py-8 px-12 lg:py-16 lg:px-6">
                <div className="container mx-auto  text-azul_base ">
                    <h1 className="leading-none text-2xl font-bold mb-4 lg:text-4xl">Quem somos</h1>
                    <p className="text-base text-preto leading-none lg:w-4/5 lg:text-lg lg:leading-tight">
                        O 21º Cartório de Registro Civil das Pessoas Naturais da Saúde, da Capital de São Paulo preza pelo atendimento de qualidade, eficiência e segurança jurídica nos serviços prestados.
                    </p>
                    <br/>
                    <p className="text-base text-preto leading-none lg:w-4/5 lg:text-lg lg:leading-tight">
                        Sobre a nossa história, o cartório foi criado por Lei Estadual no dia 20 de abril de 1926 e desde essa data pratica atos de registros de nascimento, casamento e óbitos, além de suas alterações que compõem nosso acervo.
                    </p>
                    <br/>
                    <p className="text-base text-preto leading-none lg:w-4/5 lg:text-lg lg:leading-tight">
                        Desde 04 de fevereiro de 2020 tem como Oficial Titular a Dra. Giovanna Truffi Rinaldi Gruber, aprovada pela Banca Examinadora do 11º Concurso Público de Provas e Títulos para Outorga de Delegações de Notas e Registros do Tribunal de Justiça do Estado de São Paulo.
                    </p>
                    <br/>  
                    <p className="text-base text-preto leading-none lg:w-4/5 lg:text-lg lg:leading-tight">
                        Nossa equipe de colaboradores é composta por pessoas experientes e qualificadas na prestação do serviço, sempre atentos as atualizações de nosso setor. Prezamos pelos Princípios Norteadores dos Registros Públicos da Publicidade, Autenticidade, Eficácia e Segurança Jurídica (art. 1º da Lei 6.015/1973 e art. 1º da Lei 8.935/94).
                    </p>
                    <div className="flex flex-col lg:flex-row lg:justify-start">
                        <ul className="text-sm mt-6 lg:text-lg lg:mt-16 lg:flex lg:flex-wrap lg:justify-around">
                            <li className="mb-8 lg:w-2/5 lg:mb-16">
                                <div className="flex items-start">
                                    <img className="h-10" src={relogio} alt="" />
                                    <div className="text-preto ml-4">
                                        <p className="text-azul_base font-bold">Compromisso</p>
                                        <p className="leading-tight">
                                            Estamos em fase de implementação de novos procedimentos de
                                            maneira atualizada às normativas estaduais e nacionais para
                                            otimizar seu tempo inclusive no aspecto de tecnologia.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="mb-8 lg:w-2/5 lg:mb-16">
                                <div className="flex items-start">
                                    <img className="h-12" src={organizacao} alt="" />
                                    <div className="text-preto ml-4">
                                        <p className="text-azul_base font-bold">Organização e Conservação</p>
                                        <p className="leading-tight">
                                            Prezamos pelo confiança em nosso trabalho e é nosso dever cuidar de toda a documentação
                                            que nos é entregue e do acervo de maneira organizada, de acordo com padrões de conservação.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="mb-8 lg:w-2/5 lg:mb-16">
                                <div className="flex items-start">
                                    <img className="h-16" src={atendimento} alt="" />
                                    <div className="text-preto ml-4">
                                        <p className="text-azul_base font-bold">Atendimento</p>
                                        <p className="leading-tight">
                                            Consideramos o contato e a avaliação do usuário de nossos serviços muito
                                            importante para o nosso desenvolvimento. Assim buscamos atendê-lo de acordo
                                            com as suas necessidades da melhor maneira possível, sempre prontos a prestar orientações
                                            e informações técnicas e jurídicas necessárias para a realização dos atos notariais e registrais
                                            praticados por nós.
                                        </p>
                                    </div>
                                </div>
                            </li>
                            <li className="mb-8 lg:w-2/5">
                                <div className="flex items-start">
                                    <img className="h-10" src={seguranca} alt="" />
                                    <div className="text-preto ml-4">
                                        <p className="text-azul_base font-bold">Segurança Jurídica</p>
                                        <p className="leading-tight">
                                            Nosso objetivo é atuar com qualidade nos trabalhos desempenhados por nós,
                                            em conformidade com as atualizações normativas e jurisprudenciais aplicáveis
                                            aos atos jurídicos realizados a fim de garantir um serviço de excelência.
                                        </p>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    );
}

export default QuemSomosPage;
